import {AfterViewChecked, Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../auth/shared/auth.service";
import {User} from "../../auth/shared/user.model";

@Component({
  selector: 'app-layout-full',
  templateUrl: './full.component.html',
})
export class FullComponent {

  user: User;

  routeFragmentSubscription: any;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    protected router: Router
  ) {

    auth.user().subscribe(
      (user: User) => {
        this.user = user;
      },
      (error: any) => {
        auth.logout();
        this.router.navigateByUrl('/');
      }
    );

    auth.userChangeEvent.subscribe(user => this.user = user);
  }
}

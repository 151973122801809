import {
  Component,
  OnInit
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationExtras,
  Router
} from "@angular/router";
import {
  User
} from "../shared/user.model";
import {
  UsersResourceService
} from "../shared/users.resource-service";
import {
  NgForm
} from "@angular/forms";
// import {
//   useAnimation
// } from "@angular/core/src/animation/dsl";
import {
  ToastrService
} from "ngx-toastr";
import {
  DomSanitizer
} from "@angular/platform-browser";
import {
  AuthService
} from "../../../auth/shared/auth.service";
import {
  env
} from "../../../../../.env";
import {
  Trialline
} from "../../triallines/shared/trialline.model";
import {
  TriallinesResourceService
} from "../../triallines/shared/triallines.resource-service";
import {
  AuthResourceService
} from "../../../auth/shared/auth-resoruce.service";

declare var _: any;
declare var $: any;

@Component({
  selector: "app-main-users-byadmin",
  templateUrl: "./byadmin.component.html",
})
export class UsersByadminComponent implements OnInit {
  user: User;
  currentUser: User;
  
  
  email: string;
  password: string;
 
  data: {
    
    email: string;
    password: string;
    usertype: number;
  } [] = [];

  frmData: any = {};
  submitButtonBehave = false;
  
  dashboaardAccess: boolean = false;

  errors: any = {};

  appUrl = env.applicationUrl;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected usersResource: UsersResourceService,
    protected toasts: ToastrService,
    protected _sanitizer: DomSanitizer,
    private authService: AuthService,
    private triallinesResource: TriallinesResourceService,
    protected authUser: AuthResourceService
  ) {}


  ngOnInit() {}

  openInNewTab() {
    window.open(
      this.appUrl +
      "admin-mode/" +
      this.user.id +
      "/" +
      this.authService.token(),
      "_blank"
    );
  }
  isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  createCustomer(form: NgForm, event: Event) {
    this.submitButtonBehave = true;
    event.preventDefault();
    const existingCustomer = this.data.find(
      (data) => data.email === this.email
    );

    if (existingCustomer) {
      this.toasts.error("Email is already added.");
      this.submitButtonBehave = false;
      return;
    } else if (this.email && this.password) {
      if (!this.isValidEmail(this.email)) {
        this.toasts.error("Please enter a valid email address.");
        this.submitButtonBehave = false;
        return;
      } else {
        this.errors = {};
        
        this.data.push({
          email: this.email,
          password: this.password,
          usertype: 3
        });

        this.frmData = {
          email: this.email,
          password: this.password,
          usertype: 3
        };
        
        this.usersResource.create(this.frmData).toPromise()
        .then((r: any) => {
          console.log('I am here 1');
          this.submitButtonBehave = false;
          this.toasts.success("Successfully Created");
          form.reset();
        }).catch(error => {
            console.log('I am here 2')
            console.log(error);
            this.submitButtonBehave = false;
          })
          this.data = [];
      }
      console.log('I am here 3')
    } else {
      this.toasts.error('Please Fill all fields');
      this.submitButtonBehave = false;
    }


  }

 
}

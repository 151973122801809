import {Paginator} from '../../../pagination/shared/paginator.model';

export class User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;

  stripe_id: string;
  card_brand: string;
  card_last_four: string;
  practice_area: string;
  how_found: string;
  start_banner?: boolean;
  subscription_plan?: string;
  deleted_at: any;
  phone?: string;
  verified: any;
  user_type: number;
  team_access_level?: number;
  isFree: any;
  trial_ends_at?: number;
  trial_days_count?: number;

  library_except?: any;

  tlines?: any[];

  created_at: any;
  updated_at: any;
}

export class PaginatedUsers extends Paginator {
  items: User[];
}

import { UserContact } from './user.contract';

export class User implements UserContact {
  id: number;
  email: string;
  first_name: string;
  last_name: string;

  start_banner?: boolean;
  subscription_plan?: string;

  phone?: string;

  user_type?: number;
  team_access_level?: number;

  stripe_id: string;
  card_brand: string;
  card_last_four: string;

  trial_ends_at?: number;
  trial_days_count?: number;

  library_except?: any;
}

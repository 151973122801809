import {Injectable} from "@angular/core";
import {Response} from "@angular/http";
import {Observable} from "rxjs/Observable";

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import {RestService} from "../../../rest/shared/rest.service";

@Injectable()
export class OptionsResourceService {
  constructor(public http: RestService) {
  }

  backgrounds(): Observable<any> {
    return this.http.get('/file/backgrounds').map(
      (response: Response) => {
        let user = response.json().body.backgrounds;

        return user;
      }
    );
  }
  
  deleteBackground(id: number): Observable<any> {
    return this.http.post('/file/background/' + id, '').map(
      (response: Response) => {
        let user = response.json().body.backgrounds;
        
        return user;
      }
    );
  }
}

import {Router} from "@angular/router";
import {Headers, Http} from "@angular/http";
import {Injectable, Output, EventEmitter} from "@angular/core";

import {env} from "../../../../.env";
import {User} from "./user.model";
import {Observable} from "rxjs";
import {Response} from "@angular/http";

@Injectable()
export class AuthService {
  @Output() userChangeEvent: EventEmitter<User | any> = new EventEmitter(true);

  userModel: User;

  protected oauthTokenName = 'jwt';
  public authenticate: boolean;

  constructor(protected http: Http, protected router: Router) {
    this.updateAuthenticate();
  }

  check(): boolean {
    return localStorage.getItem(this.oauthTokenName) != null;
  }

  emit(user?: User, nullable?: boolean) {
    if (nullable == true) {
      return this.userChangeEvent.emit(null);
    }

    if (user == null) {
      this.getUser();
    } else {

      this.userModel = user
    }

    this.userChangeEvent.emit(user);
  }

  user(): EventEmitter<User> {
    if (!this.check()) {
      return this.userChangeEvent;
    }

    this.getUser();

    return this.userChangeEvent;
  }

  token(): string {
    return localStorage.getItem(this.oauthTokenName);
  }

  store(token: string): void {
    this.user();
    localStorage.setItem(this.oauthTokenName, token);
    this.updateAuthenticate();
  }

  logout(): void {
    this.emit(null, true);
    localStorage.removeItem(this.oauthTokenName);
    this.updateAuthenticate();
  }

  updateAuthenticate(): void {
    this.authenticate = this.check();
  }

  loadUser() {

    return new Promise((resolve, reject) => {

      let headers = new Headers;
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.token());

      this.http.get(env.apiUrl + 'user', {headers: headers}).map(r => r.json().body.user).subscribe(
        (user: User) => {
          this.userModel = user;
          this.emit(user);
          resolve(user);
        },
        (error: any) => {
          reject(error);
        }
      );
    })
  }

  protected getUser(): void {
    let headers = new Headers;
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.token());

    this.http.get(env.apiUrl + 'user', {headers: headers}).map(r => r.json().body.user).subscribe(
      (user: User) => {
        this.userModel = user;
        this.emit(user);
      },
      (error: any) => {
        this.logout();
        this.router.navigateByUrl('/');
      }
    );
  }

  register(data): Observable<User> {
    let headers = new Headers;
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.token());

    return this.http.post(env.apiUrl + 'admin/create', data, {headers: headers}).map((response: Response) => {
      let body = response.json().body;
      return body.user;
    });
  }
}

import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {MainRoutingModule} from "./main-routing.module";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {LayoutsModule} from "../layouts/layouts.module";
import {PaginationModule} from "../pagination/pagination.module";
import {UsersModule} from "./users/user.module";
import {TriallinesModule} from "./triallines/triallines.module";
import {OptionsModule} from "./options/options.module";
import {MainResourceService} from "./shared/main.resource-service";
import {DashboardResolver} from "./shared/dashboard.resolver";
import {Daterangepicker} from 'ng2-daterangepicker';
import { CouponComponent } from './coupon/coupon.component';
import { CreatecouponComponent } from './coupon/createcoupon/createcoupon.component';
import { CouponResourceService } from './coupon/shared/coupon.resource-service';
import { SubscriptionDiscountsComponent } from './subscription-discounts/subscription-discounts.component';
import { SubscriptionDiscountsResourceService } from './subscription-discounts/shared/subscription-discounts.resource-service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    MainRoutingModule,
    LayoutsModule,
    PaginationModule,
    UsersModule,
    TriallinesModule,
    OptionsModule,
    // NgDateRangePickerModule,
    Daterangepicker
    // NgxDaterangepickerMd.forRoot()
  ],
  declarations: [
    DashboardComponent,
    CouponComponent,
    CreatecouponComponent,
    SubscriptionDiscountsComponent    
  ],
  providers: [
    MainResourceService,
    CouponResourceService,
    DashboardResolver,
    SubscriptionDiscountsResourceService
  ]
})
export class MainModule {
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FullComponent} from "../layouts/full/full.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {UsersComponent} from "./users/users.component";
import {UsersResolver} from "./users/shared/users.resolver";
import {UserResolver} from "./users/shared/user.resolver";
import {UsersDeatilsComponent} from "./users/details/details.component";
import {TriallinesResolver} from "./triallines/shared/triallines.resolver";
import {TriallinesComponent} from "./triallines/triallines.component";
import {BackgroundsComponent} from "./options/backgrounds/backgrounds.component";
import {BackgroundsResolver} from "./options/shared/backgrounds.resolver";
import {TriallineDeatilsComponent} from "./triallines/details/details.component";
import {TriallineResolver} from "./triallines/shared/trialline.resolver";
import {DashboardResolver} from "./shared/dashboard.resolver";
import { UsersByadminComponent } from './users/byadmin/byadmin.component';
import { CouponComponent } from './coupon/coupon.component';
import { CreatecouponComponent } from './coupon/createcoupon/createcoupon.component';
import { SubscriptionDiscountsComponent } from './subscription-discounts/subscription-discounts.component';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      // Dashboard
      {
        path: 'dashboard',
        component: DashboardComponent,
        resolve: {
            // counts: DashboardResolver,
          // gross_revenue: DashboardResolver
        }
      },
      {path: 'users', component: UsersComponent, resolve: {users: UsersResolver}},
      {path: 'users/:id', component: UsersDeatilsComponent, resolve: {user: UserResolver}},
      {path: 'coupon', component: CouponComponent, resolve: {user: UserResolver}},
      {path: 'createcoupon', component: CreatecouponComponent, resolve: {user: UserResolver}},
      {path: 'subscription/discounts', component: SubscriptionDiscountsComponent, resolve: {user: UserResolver}},
      // { path: 'users/:id/access', component: UserAccessComponent, resolve: {user: UserResolver} },
      {path: 'users/by/admin', component: UsersByadminComponent, resolve: {users: UsersResolver}},
      {path: 'triallines', component: TriallinesComponent, resolve: {triallines: TriallinesResolver}},
      {path: 'triallines/:id', component: TriallineDeatilsComponent, resolve: {trialline: TriallineResolver}},
      {path: 'backgrounds', component: BackgroundsComponent, resolve: {backgrounds: BackgroundsResolver}}
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class MainRoutingModule {
}

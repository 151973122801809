import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { CouponResourceService } from "../shared/coupon.resource-service";
import { Coupon } from "../shared/coupon.model";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "app/auth/shared/auth.service";
import { AuthResourceService } from "app/auth/shared/auth-resoruce.service";

@Component({
  selector: "app-createcoupon",
  templateUrl: "./createcoupon.component.html",
  styleUrls: ["./createcoupon.component.scss"],
})
export class CreatecouponComponent implements OnInit {
  coupon: Coupon;
  frmData: any = {};  
  name: string;
  duration: any [];
  discount_type: any [];
  amount: number;
  data: {
    name: string;
    duration: any [];
    discount_type: any [];
    amount: number;
  }[] = [];
  errors: any = {};
  showError: boolean = false;
  dashboaardAccess: false;
  
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected couponResource: CouponResourceService,
    protected toasts: ToastrService,
    private authService: AuthService,
    protected authUser: AuthResourceService
  ) {
    this.duration = [
      'Choose Coupon Duration',
      'once',
      'forever'
    ];
    this.discount_type = [
      'percent_off',
      'amount_off'
    ];
  }

  ngOnInit() {}

  sanitizeInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const sanitizedValue = value.replace(/[^\d.%]/g, "");

    this.showError = false;

    if (!this.isValidPercentage(sanitizedValue)) {
      this.showError = true;
    }

    input.value = sanitizedValue;
  }

  isValidPercentage(value: string): boolean {
    return /^\d+(\.\d+)?%?$/.test(value);
  }

  createCoupon(form: NgForm, event: Event) {
   
    let error = false;
    if(form.value.name == undefined) {
      this.toasts.error("Coupon Code field is required");
      error = true;
    } else if(form.value.duration == undefined) {
      this.toasts.error("Duration field is required");
      error = true;
    } else if(form.value.discount_type == undefined) {
      this.toasts.error("Discount Type field is required");
      error = true;
    } else if(form.value.amount == undefined) {
      this.toasts.error("Amount field is required");
      error = true;
    } else if(isNaN(form.value.amount)) {
      this.toasts.error("Amount should be numbers only");
      error = true;
    }

    if(!error) {
      
      this.frmData = {
        name: form.value.name,
        duration: form.value.duration,
        discount_type: form.value.discount_type,
        amount: parseInt(form.value.amount + '00')
      };

      this.couponResource.create(this.frmData).toPromise()
        .then((r: any) => {
          
          this.toasts.success("Successfully Created");
          form.reset();
          this.router.navigateByUrl('/coupon')
        }).catch(error => {
            console.log(error);
            this.toasts.error(error.message);
          })
          this.data = [];
      }
      
    }
   
    
    
    
    // this.toasts.success("Successfully Added");
    // form.reset();
    
  
}

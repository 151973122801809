import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UsersResourceService} from "../users/shared/users.resource-service";
import {User} from "../users/shared/user.model";
import * as moment from "moment";
import {MainResourceService} from "../shared/main.resource-service";
import {AuthResourceService} from "../../auth/shared/auth-resoruce.service";
import {DashboardResolver} from "../shared/dashboard.resolver";


@Component({
  selector: 'app-main-dashboard',
  templateUrl: './dashboard.component.html'
})

export class DashboardComponent implements OnInit {

  counts: any;
  gross_revenue: any;
  currentUser: User;
  constUnits: any = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected usersResource: UsersResourceService,
    protected mainResource: MainResourceService,
    protected user: AuthResourceService,
    protected countsResolver: DashboardResolver,
  ) {
  }

  ngOnInit() {
    this.user.user().subscribe((user: User) => {
      this.currentUser = user;
      if (typeof user.user_type == "undefined" || user.user_type != 0) {
        this.router.navigateByUrl('/users');
      }
    });

    // this.route.data.subscribe((data: { counts: any }) => {
    //   this.counts = data.counts;
    // });

    this.mainResource.counts()
      .toPromise().then((counts: any) => {
      this.counts = counts;
    })
      .catch((error: any) => {
      });

    // this.route.data.subscribe((data: { gross_revenue: any }) => {
    //   this.counts.gross_revenue = data.gross_revenue;
    // });

  }

  redirectToUserPage(user: User) {
    this.router.navigateByUrl('/users/' + user.id);
  }

  endSubscription(user: User) {
    if (confirm('Are you sure you want to end user subscription.')) {
      this.usersResource.endUserSubscription(user.id).toPromise()
        .then(() => {
          console.log('');
        });
    }
  }

  deleteUser(user: User) {
    if (confirm('You are about to delete account ' + user.first_name + ' ' + user.last_name + '. Are you sure you want to delete this user\'s account?'
    )) {
      this.usersResource.deleteUser(user.id).toPromise()
        .then(() => {
          // window.location.reload();
        });
    }
  }

  round(val) {
    return val.toFixed(2);
  }

  niceBytes(x) {
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l)
      n = n / 1024;

    //include a decimal point and a tenths-place digit if presenting
    //less than ten of KB or greater units
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + this.constUnits[l]);
  }

  srange: string;
  urange: string;
  prange: string;
  grossRange: string;

  public daterange: any = {};

  // see original project for full list of options
  // can also be setup using the config service to apply to multiple pickers
  public options: any = {
    locale: {format: 'MM/DD/YYYY'},
    alwaysShowCalendars: false,
    startDate: moment().startOf('month'),
    endDate: moment().add(1, 'days'),
    ranges: {
      'Today': [moment(), moment().add(1, 'days')],
      // 'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      // 'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'This Year': [moment().startOf('year'), moment()],
      'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
    }
  };

  public selectedDate(value: any, field: string, datepicker?: any) {
    let self = this;

    let start = moment(value.start).format("MM/DD/YYYY");
    let end = moment(value.end).format("MM/DD/YYYY");
    let range = start + '-' + end;

    switch (field) {
      case 'srange':
        this.srange = range;
        break;
      case 'urange':
        this.urange = range;
        break;
      case 'prange':
        this.prange = range;
        break;
      case 'grossRange':
        this.grossRange = range;
        break;
    }

    // this is the date the iser selected
    // console.log(range);

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    let options = {
      params: {
        srange: this.srange,
        urange: this.urange,
        prange: this.prange,
        grossRange: this.grossRange,
      }
    };

    this.mainResource.counts(options)
      .toPromise().then((counts: any) => {
      self.counts = counts;
    }).catch((error: any) => {
    });

    // this.mainResource.gross_revenue(options)
    //   .toPromise().then((gross_revenue: any) => {
    //   self.gross_revenue = gross_revenue;
    // }).catch((error: any) => {
    // });


    // or manupulat your own internal property
    // this.daterange.start = value.start;
    // this.daterange.end = value.end;
    // this.daterange.label = value.label;
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {User} from "../shared/user.model";
import {UsersResourceService} from "../shared/users.resource-service";
import {NgForm} from "@angular/forms";
// import {useAnimation} from "@angular/core/src/animation/dsl";
import {ToastrService} from "ngx-toastr";
import {DomSanitizer} from "@angular/platform-browser";
import {AuthService} from "../../../auth/shared/auth.service";
import {env} from "../../../../../.env";
import {Trialline} from "../../triallines/shared/trialline.model";
import {TriallinesResourceService} from "../../triallines/shared/triallines.resource-service";
import {AuthResourceService} from "../../../auth/shared/auth-resoruce.service";


declare var _: any;
declare var $: any;

@Component({
  selector: 'app-main-users-details',
  templateUrl: './details.component.html'
})

export class UsersDeatilsComponent implements OnInit {
  user: User;
  currentUser: User;

  dashboaardAccess: boolean = false;

  errors: any = {};

  appUrl = env.applicationUrl;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected usersResource: UsersResourceService,
    protected toasts: ToastrService,
    protected _sanitizer: DomSanitizer,
    private authService: AuthService,
    private triallinesResource: TriallinesResourceService,
    protected authUser: AuthResourceService,
  ) {
  }

  userTypes = {
    0: 'Super admin',
    1: 'Admin',
    2: 'Owner',
    3: 'Regular'
  };

  userAccess = {
    1: 'Member',
    // 2: 'Less than manager',
    3: 'Manager'
  };

  ngOnInit() {

    this.authUser.user().subscribe((user: User) => {
      this.currentUser = user;
    });

    this.route.data.subscribe((data: { user: User }) => {
      this.user = data.user;
    });

    // this.user.user().subscribe((user: User) => {
    //   this.currentUser = user;
    // });
  }

  openInNewTab() {

    window.open(this.appUrl + 'admin-mode/' + this.user.id + '/' + this.authService.token(), '_blank');
  }

  updateProfile(form: NgForm) {

    this.errors = {};
    let data = form.value;

    data['user_type'] = this.user.user_type;

    this.usersResource.update(this.user.id, data).toPromise()
      .then((user: User) => {
        _.extend(this.user, user);
        this.toasts.success('Successfully updated')
      }).catch(error => {

      if (error.status == 422) {
        let errorData: any = error.json();
        this.errors = errorData.data;
      } else {
        console.log(error.json());
      }
    })
  }

  updatePassword(form: NgForm) {

    this.errors = {};
    let data = form.value;

    this.usersResource.updatePassword(this.user.id, data).toPromise()
      .then((success) => {

        this.toasts.success('Password successfully changed')
      }).catch(error => {

      if (error.status == 422) {
        let errorData: any = error.json();
        this.errors = errorData.data;
      } else {

        console.log(error.json());
      }
    })
  }

  createTrialline(form: NgForm) {

    this.errors = {};
    let data = form.value;

    data.user_id = this.user.id;

    if (!data.free) data.free = false;

    this.triallinesResource.create(data).toPromise()
      .then((trialline: Trialline) => {

        form.reset();
        this.user.tlines.push(trialline);
        this.toasts.success('Successfully created')
      }).catch(error => {

      if (error.status == 422) {
        let errorData: any = error.json();
        this.errors = errorData.data;
      } else {

        console.log(error.json());
      }
    })
  }
}

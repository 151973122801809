import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { Http, Response, RequestOptionsArgs } from "@angular/http";

import { AuthService } from "../../auth/shared/auth.service";
import { Rest } from "./rest";

@Injectable()
export class RestService extends Rest
{
  public constructor(protected http: Http, protected auth: AuthService, protected router: Router) {
    super(http, auth, router);
  }

  public get(url: string, options?: RequestOptionsArgs, events: boolean = true): Observable<Response>
  {
    return this.intercept('get', url, null, options, events);
  }

  public post(url: string, body: any, options?: RequestOptionsArgs, events: boolean = true): Observable<Response>
  {
    return this.intercept('post', url, body, options);
  }

  public put(url: string, body: any, options?: RequestOptionsArgs, events: boolean = true): Observable<Response>
  {
    return this.intercept('put', url, body, options);
  }

  public delete(url: string, options?: RequestOptionsArgs, events: boolean = true): Observable<Response>
  {
    return this.intercept('delete', url, options);
  }
}

import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {UsersResourceService} from "./users.resource-service";
import {PaginatedUsers} from "./user.model";

@Injectable()
export class UsersResolver implements Resolve<any>
{
  constructor(protected usersResource : UsersResourceService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<PaginatedUsers>
  {
    return this.usersResource.paginate(route.queryParams['page'])
      .toPromise().then((users: PaginatedUsers) => {

        return <any>users;
      })
      .catch((error: any) => {});
  }
}

import { Injectable } from "@angular/core";
import {RequestOptionsArgs, Response} from "@angular/http";
import { Observable } from "rxjs/Observable";

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { RestService } from "../../../rest/shared/rest.service";
import {PaginatedUsers, User} from "./user.model";

@Injectable()
export class UsersResourceService
{
  constructor(public http: RestService) {}

  paginate(page: number = 1, filter = {}, sort = {}): Observable<PaginatedUsers>
  {
    let opts: RequestOptionsArgs = {
      search: Object.assign({}, filter, sort, {page: page})
    };
    return this.http.get('/admin/users', opts).map(
      (response: Response) => {
        let body = response.json().body;

        return body;
      }
    );
  }

  get(id, withParam: string = null): Observable<User> {
    return this.http.get('/admin/users/' + id + (withParam ? '?with=' + withParam : '')).map(
      (response: Response) => {
        let user = response.json().body.user;

        return user;
      }
    );
  }

  update(id, data): Observable<User> {
    return this.http.post('/admin/users/' + id, data).map(
      (response: Response) => {
        let user = response.json().body.user;

        return user;
      }
    );
  }

  create(data): Observable<User> {
    return this.http.post('/admin/users/create', data).map(
      (response: Response) => {
        let user = response.json();
        return user;
      }
    );
  }

  updatePassword(id, data): Observable<any> {
    return this.http.post('/admin/users/' + id + '/password', data).map(
      (response: Response) => {
        return response.json().body;
      }
    );
  }

  endUserSubscription(id): Observable<any> {
    return this.http.delete('/admin/users/end-subscription/' + id, {}).map(
      (response: Response) => {
        return response.json().body;
      }
    );
  }

  deleteUser(id): Observable<any> {
    return this.http.delete('/admin/users/' + id, {}).map(
      (response: Response) => {
        return response.json().body;
      }
    );
  }

  softDeleteUser(id): Observable<any> {
    return this.http.delete('/admin/users/ban/' + id, {}).map(
      (response: Response) => {
        return response.json().body;
      }
    );
  }

  activateDeleteUser(id): Observable<any> {
    return this.http.post('/admin/users/activate/' + id, {}).map(
      (response: Response) => {
        return response.json().body;
      }
    );

  }

  setfreeuserapi(data) {
    return this.http.post('/admin/users/free',data).map(
      (response: Response) => {
        return response.json();
      }
    );

  }

}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import {AuthService} from "../auth/shared/auth.service";

@Component({
  selector: 'app-starter',
  template: ''
})

export class StarterComponent implements OnInit{

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {

    this.router.navigateByUrl(this.auth.check() ? '/dashboard' : '/login')
  }

}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RestService } from "./shared/rest.service";

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    RestService
  ]
})
export class RestModule {}

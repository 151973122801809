import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {UsersResourceService} from "./users.resource-service";
import {PaginatedUsers, User} from "./user.model";

@Injectable()
export class UserResolver implements Resolve<any>
{
  constructor(protected usersResource : UsersResourceService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<User>
  {
    return this.usersResource.get(route.params['id'])
      .toPromise().then((user: User) => {
        return <any>user;
      })
      .catch((error: any) => {});
  }
}

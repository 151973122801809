import { env } from '../../../../.env';
import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { Output, EventEmitter } from "@angular/core";
import { HttpEvent, HttpStartEvent, HttpFinishEvent } from "./http.events";
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from "@angular/http";

import { AuthService } from "../../auth/shared/auth.service";

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

export abstract class Rest
{
  @Output() events: EventEmitter<HttpEvent> = new EventEmitter;

  protected apiUrl: string;

  constructor (protected http: Http, protected auth: AuthService, protected router: Router) {
    this.setApiUrl();
  }

  protected intercept(method: string, url: string, body?: any, options?: RequestOptionsArgs, events: boolean = true) : Observable<Response>
  {
    if (events) {

      let eventStart = new HttpStartEvent(url);
      this.events.next(eventStart);
    }

    return this.createHttpEntity(method, url, body, options).map((response: Response): Response => {

      if (events) {

        let eventFinish = new HttpFinishEvent(url);
        this.events.next(eventFinish);
      }
      return response;
    }).catch((error: Response) => {

      if (events) {
        let eventFinish = new HttpFinishEvent(url);
        this.events.next(eventFinish);
      }

      if (error.status == 401) {
        this.auth.logout();
        this.router.navigate(['/']);
      }

      if (error.status == 503) {
        this.auth.logout();
        this.router.navigate(['/maintenance']);
      }

      return Observable.throw(error);
    });
  }

  private createHttpEntity(method: string, url: string, body?: any, options?: RequestOptionsArgs): Observable<Response>
  {
    let requestOptions = this.getDefaultRequestOptions(options);

    if (url.startsWith('/')) {
      url = url.substr(1);
    }

    if (method == 'get' || method == 'delete') {
      return eval('this.http.' + method + '(this.apiUrl + url, requestOptions)');
    }

    return eval('this.http.' + method + '(this.apiUrl + url, body, requestOptions)')
  }


  protected getDefaultRequestOptions(options?: RequestOptionsArgs): RequestOptionsArgs
  {
    if (! options) {
      options = new RequestOptions();
    }

    if (! options.headers) {
      options.headers = new Headers();
    }

    options.headers.append('Accept', 'application/json');

    if (this.auth.check()) {
      options.headers.append('Authorization', 'Bearer ' + this.auth.token());
    }

    return options;
  }

  private setApiUrl(): void
  {
    this.apiUrl = env.apiUrl;
  }
}

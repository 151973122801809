import { NgModule } from '@angular/core';
import { HttpModule } from "@angular/http";
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import {PaginationModule} from "../../pagination/pagination.module";
import {OptionsResourceService} from "./shared/options.resource-service";
import {FileUploadModule} from "ng2-file-upload";
import {BackgroundsComponent} from "./backgrounds/backgrounds.component";
import {BackgroundsResolver} from "./shared/backgrounds.resolver";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpModule,
    RouterModule,
    FileUploadModule,
    // PaginationModule
  ],
  declarations: [
    BackgroundsComponent
  ],
  providers: [
    OptionsResourceService,
    BackgroundsResolver
  ]
})
export class OptionsModule {}

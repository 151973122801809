import { NgModule } from '@angular/core';
import { HttpModule } from "@angular/http";
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import {PaginationModule} from "../../pagination/pagination.module";
import {UsersComponent} from "./users.component";
import {UsersResolver} from "./shared/users.resolver";
import {UsersResourceService} from "./shared/users.resource-service";
import {UserResolver} from "./shared/user.resolver";
import {UsersDeatilsComponent} from "./details/details.component";
import {UserAccessComponent} from "./details/access/access.component";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {SortDirective} from "./shared/sort.directive";
import { UsersByadminComponent } from './byadmin/byadmin.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpModule,
    RouterModule,
    PaginationModule,
    CKEditorModule
  ],
  declarations: [
    UsersComponent,
    UsersDeatilsComponent,
    UsersByadminComponent,
    UserAccessComponent,
    SortDirective
  ],
  providers: [
    UsersResolver,
    UserResolver,
    UsersResourceService
  ]
})
export class UsersModule {}

import { Component, Output, EventEmitter, Input, OnChanges } from "@angular/core";

import { Pages } from "./shared/pages.model";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnChanges
{
  lastPage: number;
  currentPage: number;
  pages: Object;

  @Input()  data: Pages;
  @Output() paginator: EventEmitter<number> = new EventEmitter;

  ngOnChanges()
  {
    this.currentPage = this.data.current_page;
    this.lastPage = this.data.last_page;
    this.initPages(this.lastPage);
  }

  paginate(page: number): void
  {
    this.paginator.next(page);
  }

  initPages(lastPage: number): void
  {
    let data = [];

    for (let i = 1; i <= lastPage; i++) {
      data.push(i);
    }

    this.pages = data;
  }
}

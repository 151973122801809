import {ChangeDetectorRef, Injectable} from "@angular/core";
import {RequestOptionsArgs, Response} from "@angular/http";
import {Observable} from "rxjs/Observable";

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {RestService} from "../../rest/shared/rest.service";

@Injectable()
export class MainResourceService {

  // filter = {
  //   from: '01.01.2019',
  //   to: 'now'
  // };

  constructor(
    public http: RestService,
    // public _changeDetector: ChangeDetectorRef,
  ) {
  }

  counts(options: {} = {}): Observable<any> {
    // return this.http.get('/admin/counts?srange=02/01/2017-02/28/2019',{search: params}).map(
    return this.http.get('/admin/counts', options).map(
      (response: Response) => {
        return response.json().body.counts;
      }
    );
  }

  // gross_revenue(options: {} = {}): Observable<any> {
  //   // return this.http.get('/admin/counts?srange=02/01/2017-02/28/2019',{search: params}).map(
  //   return this.http.get('/admin/gross_revenue', options).map(
  //     (response: Response) => {
  //       return response.json().body.gross_revenue;
  //     }
  //   );
  // }

  // gross(filter = {}): Observable<any> {
  //   let opts: RequestOptionsArgs = {
  //     search: Object.assign({}, filter)
  //   };
  //   return this.http.get('/admin/gross', opts).map(
  //     (response: Response) => {
  //       return response.json().body.counts;
  //     }
  //   );
  // }

  // changeRange(from, to = '29.01.2019') {
  //   this.filter.from = from;
  //   this.filter.to = to;
  //
  //   this._changeDetector.detectChanges();
  // }

}

import { NgModule } from '@angular/core';
import { HttpModule } from "@angular/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { AuthCanActivate } from "./shared/auth.guard";
import { GuestCanActivate } from "./shared/guest.guard";
import { AuthResourceService } from "./shared/auth-resoruce.service";
import {NotLowCanActivate} from "./shared/low-plan.guard";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    RouterModule,
    // ValidatorModule,
    // RecaptchaModule.forRoot(), RecaptchaFormsModule
  ],
  declarations: [
    // RegisterComponent,
    LoginComponent,
    // ConfirmComponent,
    // ConfirmInvitationComponent,
    // SendComponent,
    // ResetComponent
  ],
  providers: [
    AuthCanActivate,
    GuestCanActivate,
    AuthResourceService
  ]
})
export class AuthModule {}

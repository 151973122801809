import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {MainResourceService} from "./main.resource-service";

@Injectable()
export class DashboardResolver implements Resolve<any>
{
  constructor(protected mainResource: MainResourceService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any>
  {
    return this.mainResource.counts()
      .toPromise().then((counts: any) => {
        return counts;
      })
      .catch((error: any) => {});
  }
}

import { Response } from "@angular/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { User } from "./user.model";
import { RestService } from "../../rest/shared/rest.service";

@Injectable()
export class AuthResourceService
{
  constructor(public http: RestService) {}

  user(): Observable<User>
  {
    return this.http.get('user').map(r => r.json().body.user);
  }

  login(data): Observable<any>
  {
    data.admin = 1;
    return this.http.post('auth/login', data).map(r => r.json().body);
  }

  resendActivationEmail(email): Observable<any>
  {
    return this.http.post('admin/users/register/resend', {email: email}    ).map((response: Response) => {

      return response.json().body;
    });
  }

  resendPasswordEmail(data): Observable<any>
  {
    return this.http.post('admin/users/password/reset', data).map((response: Response) => {

      return response.json().body;
    });
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { BackdropsComponent } from './elements/backdrops';
import { EmptyPageComponent } from './pages/empty-page';
import { ErrorPageComponent } from './pages/error-page';
import { AppRoutingModule } from "./app-routing.module";
import { AuthModule } from "./auth/auth.module";
import { AuthService } from "./auth/shared/auth.service";
import { LayoutsModule } from "./layouts/layouts.module";
import { RestModule } from "./rest/rest.module";
import { MainModule } from "./main/main.module";
import { PaginationModule } from "./pagination/pagination.module";
import { StarterComponent } from "./starter/starter.component";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
  declarations: [
    AppComponent,
    BackdropsComponent,
    EmptyPageComponent,
    ErrorPageComponent,
    StarterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    LayoutsModule,
    AuthModule,
    RestModule,
    MainModule,
    PaginationModule,
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }

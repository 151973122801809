import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "../../../auth/shared/auth.service";
import {Router} from "@angular/router";
import {User} from "../../../auth/shared/user.model";
import {AuthResourceService} from "../../../auth/shared/auth-resoruce.service";
import {ToastrService} from "ngx-toastr";

declare var $: any;

@Component({
  selector: 'app-layouts-full-navbar',
  templateUrl: './navbar.component.html'
})

export class NavbarComponent implements OnInit {

  currentUser: User;

  errors: any = {};
  processing: boolean = false;
  newUser: User;

  constructor(
    protected auth: AuthService,
    protected user: AuthResourceService,
    protected router: Router,
    private toasts: ToastrService,
  ) {
  }

  logout() {
    this.auth.logout();
    this.router.navigateByUrl('/login');
  }

  toggleLayout(): void {
    const body = $('body');
    const collapsed = body.attr('data-collapsed') === 'true' ? true : false;
    body.attr('data-collapsed', !collapsed);
    const layout = body.attr('data-layout');
    if (layout === 'sidebar-over-1') {
      var backdrop = $('.left-sidebar-backdrop');
      if (backdrop.hasClass('in')) {
        backdrop.removeClass('fade');
        backdrop.removeClass('in');
      } else {
        backdrop.toggleClass('fade in');
      }
    }
  }

  toggleFullscreen(): void {
    const body = $('body');
    const value = body.attr('data-fullscreen') === 'true' ? true : false;
    body.attr('data-fullscreen', !value);
  }

  register(form: any) {
    this.processing = true;

    this.auth.register(form.value).toPromise().then(
      (newUser) => this.handleRegister(newUser, form),
      (error: Response) => this.handleError(error)
    );
  }

  handleRegister(newUser: any, form: any) {
    form.reset();
    this.processing = false;
    this.newUser = newUser;
    $('#create-user-modal').modal('hide');
    $('#congratulations-modal').modal('show');
  }

  handleError(error: Response) {
    this.processing = false;

    if (error.status != 422) {
      return;
    }
    let errorData = error.json();
    // this.errors = errorData.data;
    console.log(errorData);

    for (let entry in errorData) {
      this.toasts.error(errorData[entry][0]);
    }
  }

  redirectToCreatedAdmin() {
    console.log('/users/' + this.newUser.id);
    this.router.navigateByUrl('/users/' + this.newUser.id)
  }

  ngOnInit(): void {
    this.user.user().subscribe((user: User) => {
      this.currentUser = user;
    });
  }
}


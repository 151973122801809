import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {OptionsResourceService} from "../shared/options.resource-service";
import {FileUploader} from "ng2-file-upload";
import {env} from "../../../../../.env";
import {AuthService} from "../../../auth/shared/auth.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-main-options-backgrounds',
  templateUrl: './backgrounds.component.html',
  styleUrls: ['./backgrounds.component.scss']
})

export class BackgroundsComponent implements OnInit
{
  backgrounds: any[];

  apiPath = env.apiUrl;

  public uploader: FileUploader;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected oprionsResource: OptionsResourceService,
    private authService: AuthService,
    protected toasts: ToastrService
  ) {

    this.uploader = new FileUploader({
      url:   env.apiUrl + 'admin/backgrounds',
      authToken: 'Bearer ' + authService.token(),
    });

    this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {

      let body = JSON.parse(response).body;
      this.backgrounds.push(body.background);
    };
  }

  value(name) {

    return name.replace(/\.[^/.]+$/, "");
  }

  ngOnInit()
  {
    this.route.data.subscribe((data: {backgrounds: any[]}) => {

      this.backgrounds = data.backgrounds;
    });
  }

  upload(item, attachText) {

    if (! attachText) {

      this.toasts.error('Attach text field is required')
    } else {

      item.options.additionalParameter = {
        title: attachText,
        is_public: 1
      };
      item.upload();
    }
  }
  
  remove(id: number, index: number) {
    this.oprionsResource.deleteBackground(id).toPromise()
      .then((data: any) => {
        this.toasts.success('Background removed')
        this.backgrounds.splice(index, 1)
      }).catch((error) => {
      this.toasts.error(error.json().error.message);
    });
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyPageComponent } from "./pages/empty-page";
import { ErrorPageComponent } from "./pages/error-page";
import { GuestCanActivate } from "./auth/shared/guest.guard";
import { LoginComponent } from "./auth/login/login.component";
import { EmptyComponent } from "./layouts/empty/empty.component";
import { AuthCanActivate } from "./auth/shared/auth.guard";
import { StarterComponent } from "./starter/starter.component";

const routes: Routes = [
  { path: '', component: StarterComponent },
  {
    path: '',
    canActivate: [AuthCanActivate],
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
  },
  {
    path: '',
    component: EmptyComponent,
    canActivate: [GuestCanActivate],
    children: [
      { path: 'login', component: LoginComponent },
      { path: '**', component: ErrorPageComponent }
    ]
  },
  // { path: '', component: EmptyPageComponent },
  // { path: 'pages/empty-page', component: EmptyPageComponent },
  // { path: '**', component: ErrorPageComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}

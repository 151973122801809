import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {OptionsResourceService} from "./options.resource-service";

@Injectable()
export class BackgroundsResolver implements Resolve<any>
{
  constructor(protected oprionsResource : OptionsResourceService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any>
  {
    return this.oprionsResource.backgrounds()
      .toPromise().then((backgrounds: any[]) => {

        return backgrounds;
      })
      .catch((error: any) => {});
  }
}

import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
// import {useAnimation} from "@angular/core/src/animation/dsl";
import {ToastrService} from "ngx-toastr";
import {User} from "../../shared/user.model";
import {env} from "../../../../../../.env";
import {DomSanitizer} from "@angular/platform-browser";
import {AuthService} from "../../../../auth/shared/auth.service";

declare var _: any;
declare var $: any;

@Component({
  selector: 'app-main-users-access',
  templateUrl: './access.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UserAccessComponent implements OnInit, AfterViewInit {

  @Input() user: User;

  appUrl = env.applicationUrl;

  errors: any = {};

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              // protected usersResource: UsersResourceService,
              protected toasts: ToastrService,
              protected _sanitizer: DomSanitizer,
              protected authService: AuthService
  ) {}

  ngOnInit() {
    // this.route.data.subscribe((data: { user: User }) => {
    //
    //   this.user = data.user;
    // });
  }

  ngAfterViewInit() {

    $("html, body").animate({ scrollTop: $('#accessor').offset().top }, 1000);
  }

  windowHeight() {

    return window.innerHeight;
  }

  getSafeUrl() {

    return this._sanitizer.bypassSecurityTrustResourceUrl(this.appUrl + 'admin-mode/' + this.user.id + '/' + this.authService.token());
  }
}

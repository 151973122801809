import { Pages } from "./pages.model";
import { Counts } from "./counts.model";

export abstract class Paginator
{
  pagination: {
    counts: Counts;
    pages: Pages;
  }
}

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {PaginatedUsers} from '../users/shared/user.model';
import {UsersResourceService} from '../users/shared/users.resource-service';
import {User} from '../../auth/shared/user.model';
import {AuthResourceService} from '../../auth/shared/auth-resoruce.service';
import {env} from '../../../../.env';
import {AuthService} from '../../auth/shared/auth.service';
import { NgForm } from "@angular/forms";
import {ToastrService} from 'ngx-toastr';
import { SubscriptionDiscountsResourceService } from "./shared/subscription-discounts.resource-service";
import { CouponResourceService } from "./../coupon/shared/coupon.resource-service";

@Component({
  selector: 'app-subscription-discounts',
  templateUrl: './subscription-discounts.component.html',
  styleUrls: ['./subscription-discounts.component.scss']
})
export class SubscriptionDiscountsComponent implements OnInit {

  duration: any [];
  couponsDropdownForMonthly = {
    names: []
  };
  couponsDropdownForYearly = {
    names: []
  };
  subscriptionMonthlySelected: any = 0;
  subscriptionYearlySelected: any = 0;
  frmData: any = {};
  arr = [];
  data: {
    coupon_id: string;
    subscription: string;
  }[] = [];

  apiUrl = env.apiUrl;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public _changeDetector: ChangeDetectorRef,
    
    public toasts: ToastrService,
    protected couponResource: CouponResourceService,
    protected subscriptionDiscountsResource: SubscriptionDiscountsResourceService
    ) {
      
    }

  ngOnInit(): void {
    
    this.subscriptionDiscountsResource.get().toPromise().then((returnRes) => {
      
      for (let key in returnRes) {
          if(returnRes[key].subscription == 'month-to-month') {
            this.subscriptionMonthlySelected = returnRes[key].coupon_id;
          }
          if(returnRes[key].subscription == 'yearly') {
            this.subscriptionYearlySelected = returnRes[key].coupon_id;
          }

      }
      
    }).catch((error: any) => {
      this.toasts.success(error);
    });
    
    this.couponResource.allList().toPromise().then(
      (returnResponse) => {
       
        let count = 1;
        this.couponsDropdownForMonthly.names[0] =  [0, 'No Discount Apply'];
        this.couponsDropdownForYearly.names[0] =  [0, 'No Discount Apply'];
        
        
        console.log("returnResponse['items']", returnResponse)
        
        let element;
        
        for (let key in returnResponse) {
          element = returnResponse[key];
          console.log('element',eval(key+'+1'))
          this.couponsDropdownForMonthly.names[eval(key+'+1')] = [element.id, element.name];
          this.couponsDropdownForYearly.names[eval(key+'+1')] = [element.id, element.name];
         
        }
        
        
        
        // returnResponse['items'].forEach(element => {
        //   this.couponsDropdownForMonthly.names[count] = [element.id, element.name];
        //   this.couponsDropdownForYearly.names[count] = [element.id, element.name];
        //   count++;
        // });
         
      }
    );
    
  }

  subscriptionDiscountMonthly(form: NgForm, event: Event) {
    let error = false;
    if(form.value.coupon_id == undefined) {
      this.toasts.error("Please select atleast 1 coupon code");
      error = true;
    }
    
    if(!error) {
      
          this.frmData = {
            coupon_id: form.value.coupon_id,
            subscription: 'month-to-month'
          };
          
          this.subscriptionDiscountsResource.create(this.frmData).toPromise()
          .then((r: any) => {
            this.toasts.success("Request successfully Proceed");
            this.router.navigateByUrl('/subscription/discounts')
          }).catch(error => {
              console.log(error);
              this.toasts.error(error.message);
          })
          this.data = [];

      
    }
  }

  subscriptionDiscountYearly(form: NgForm, event: Event) {
    let error = false;
    if(form.value.coupon_id == undefined) {
      this.toasts.error("Please select atleast 1 coupon code");
      error = true;
    }

    
    if(!error) {
      
          this.frmData = {
            coupon_id: form.value.coupon_id,
            subscription: 'yearly'
          };
          
          this.subscriptionDiscountsResource.create(this.frmData).toPromise()
          .then((r: any) => {
            console.log('r',r)
            this.toasts.success("Request successfully Proceed");
            this.router.navigateByUrl('/subscription/discounts');
          }).catch(error => { alert(error)
              console.log(error);
              this.toasts.error(error.message);
          })
          this.data = [];

      
    }
  }
  
}

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {PaginatedUsers} from '../users/shared/user.model';
import {UsersResourceService} from '../users/shared/users.resource-service';
import {User} from '../../auth/shared/user.model';
import {AuthResourceService} from '../../auth/shared/auth-resoruce.service';
import {env} from '../../../../.env';
import {AuthService} from '../../auth/shared/auth.service';
import {ToastrService} from 'ngx-toastr';
import { CouponResourceService } from "./shared/coupon.resource-service";
import { PaginatedCoupons } from "./shared/coupon.model";

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent implements OnInit {

  coupons: any;
  page: any;
  reorder:any;
  
  filter = {
    name: '',
    duration: '',
    percent_off: ''
  };

  sort = {
    sort_col: 'created_at',
    sort_dir: 'asc'
  };
  
  apiUrl = env.apiUrl;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public _changeDetector: ChangeDetectorRef,
    
    public toasts: ToastrService,
    protected couponResource: CouponResourceService
    ) {}

  ngOnInit() {
    
    this.couponResource.get().toPromise().then(
      (returnResponse) => {
        console.log('response:',returnResponse)
        this.coupons = returnResponse;
      }
    );
    
  }

  paginate(page: number) {
    this.couponResource.paginate(page).toPromise().then(
      (data: PaginatedCoupons) => {

        const navigationExtras: NavigationExtras = {
          queryParams: Object.assign({}, {page: page})
        };

        this.router.navigate(['/coupon'], navigationExtras);
        this.coupons = data;
      }
    );

  }

  

}

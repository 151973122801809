import { NgModule } from '@angular/core';
import { HttpModule } from "@angular/http";
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import {PaginationModule} from "../../pagination/pagination.module";
import {TriallinesComponent} from "./triallines.component";
import {TriallinesResourceService} from "./shared/triallines.resource-service";
import {TriallinesResolver} from "./shared/triallines.resolver";
import {TriallineResolver} from "./shared/trialline.resolver";
import {TriallineDeatilsComponent} from "./details/details.component";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpModule,
    RouterModule,
    PaginationModule,
    CKEditorModule
  ],
  declarations: [
    TriallinesComponent,
    TriallineDeatilsComponent
  ],
  providers: [
    TriallinesResourceService,
    TriallinesResolver,
    TriallineResolver
  ]
})
export class TriallinesModule {}

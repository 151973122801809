import { Component } from '@angular/core';

@Component({
  selector: 'empty-page',
  templateUrl: '../pages/empty-page.html'
})

export class EmptyPageComponent {

  constructor() {
  }

}

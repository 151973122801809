import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {PaginatedUsers} from './shared/user.model';
import {UsersResourceService} from './shared/users.resource-service';
import {User} from '../../auth/shared/user.model';
import {AuthResourceService} from '../../auth/shared/auth-resoruce.service';
import {env} from '../../../../.env';
import {AuthService} from '../../auth/shared/auth.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-main-users',
  templateUrl: './users.component.html'
})

export class UsersComponent implements OnInit {

  users: PaginatedUsers;
  currentUser: User;
  page: any;
  token: string;
  actionUser = {
    email: ''
  };
  filter = {
    name: '',
    email: '',
    practice_area: '',
    how_found: '',
  };

  sort = {
    sort_col: 'created_at',
    sort_dir: 'asc'
  };

  apiUrl = env.apiUrl;

  practice_areas: any [];
  how_founds: any [];
  userTypes = {
    0: 'Super admin',
    1: 'Admin',
    2: 'Owner',
    3: 'Regular'
  };

  userAccess = {
    1: 'member',
    // 2: 'less than manager',
    2: 'manager',
    3: ''
  };

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected usersResource: UsersResourceService,
    protected user: AuthResourceService,
    public _changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    public toasts: ToastrService
    ) {
    this.practice_areas = [
      'Any',
      'Antitrust Litigation',
      'Appellate',
      'Bankruptcy',
      'Business Litigation',
      'Civil Rights',
      'Class Action/Mass Torts',
      'Construction Litigation',
      'Criminal Defense',
      'Employment & Labor',
      'Environmental Litigation',
      'Estate, Trusts & Probate',
      'Family Law',
      'Immigration',
      'Insurance Defense',
      'Intellectual Property',
      'Personal Injury - General',
      'Personal Injury - Medical Malpractice',
      'Personal Injury – Products Liability',
      'Professional Liability',
      'Real Estate',
      'Securities Law',
      'Social Security Disability',
      'Workers\' Compensation',
    ];
    this.how_founds = [
      'Any',
      'Google Search',
      'Facebook',
      'Instagram',
      'LinkedIn',
      'Twitter',
      'Attorney Referral',
      'ListServe',
      'Exhibitor Booth',
      'CLE',
      'Clio',
      'Email Invitation'
    ];
  }

  ngOnInit() {
    this.user.user().subscribe((user: User) => {
      this.currentUser = user;
    });

    this.route.data.subscribe((data: { users: PaginatedUsers }) => {
      this.users = data.users;
    });

    this.route.queryParams
      .subscribe(params => {
        this.page = params.page;
      });

      console.log("this.users", this.users, "this.users");
  }

  paginate(page: number) {
    this.usersResource.paginate(page, this.filter, this.sort).toPromise().then(
      (data: PaginatedUsers) => {

        const navigationExtras: NavigationExtras = {
          queryParams: Object.assign({}, this.filter, this.sort, {page: page})
        };

        this.router.navigate(['/users'], navigationExtras);
        this.users = data;
      }
    );
  }

  filterSelectbox(col, val) {


    this.paginate(1);

    this._changeDetector.detectChanges();
  }

  reorder(col) {

    if (col === this.sort.sort_col) {

      this.sort.sort_dir = (this.sort.sort_dir === 'asc' ? 'desc' : 'asc');
    } else {

      this.sort.sort_dir = 'asc';
      this.sort.sort_col = col;
    }

    this.paginate(1);

    this._changeDetector.detectChanges();
  }

  deleteUser(userId: any) {
    let username: string;
    this.users.items.forEach($item => {
      if ($item.id === userId) {
        username = $item.first_name + ' ' + $item.last_name;
      }
    });

    if (confirm('You are about to delete account ' + username
      + '. Are you sure you want to delete this user\'s account?'
    )) {
      this.usersResource.deleteUser(userId).toPromise()
        .then(() => {
          this.paginate(this.page);
        });
    }
  }

  softDeleteUser(userId: any) {
    let username: string;
    this.users.items.forEach($item => {
      if ($item.id === userId) {
        username = $item.first_name + ' ' + $item.last_name;
      }
    });

    if (confirm('You are about to ban account ' + username
      + '. Are you sure you want to ban this user\'s account?'
    )) {
      this.usersResource.softDeleteUser(userId).toPromise()
        .then(() => {
          this.paginate(this.page);
        });
    }
  }

  activateDeleteUser(userId: any) {
    let username: string;
    this.users.items.forEach($item => {
      if ($item.id === userId) {
        username = $item.first_name + ' ' + $item.last_name;
      }
    });

    if (confirm('You are about to activate account ' + username + '. Are you sure you want to activate this user\'s' +
      ' account?'
    )) {
      this.usersResource.activateDeleteUser(userId).toPromise()
        .then(() => {
          this.paginate(this.page);
        });
    }
  }

  getXlsUrl() {
    return this.genDownloadUrl('exel');
  }

  genDownloadUrl(type) {
    return this.apiUrl + 'admin/users/export' + '?token=' + this.authService.token();
  }

  setfreeuser(user) {
    const data = [];
    let freestatus;
    let msg;
    if (user.isFree === 0) {
      freestatus = 1;
      msg = 'User set free subscription successfully';
    }else {
      freestatus = 0;
      msg = 'User set Paid subscription successfully';
    }
   data.push({'user_id': user.id, 'isFree': freestatus});
    this.usersResource.setfreeuserapi(data).toPromise()
      .then((Response) => {
        this.paginate(this.page);
        this.toasts.success(msg);
      });
  }

  resetPassword(userId: any) {
    let username: string;
    this.users.items.forEach($item => {
      if ($item.id === userId) {
        this.actionUser.email = $item.email;
        username = $item.first_name + ' ' + $item.last_name;
      }
    });
    const email = this.actionUser.email;
    this.user.resendPasswordEmail({'email': email}).toPromise().then(
        (r: any) => {
          this.toasts.success('Mail sent successfully');
        },
        (error: Response) => {
          this.toasts.info('Already authorized');
          console.log(error);
        });
  }


  activationEmail(userId: any) {
    let username: string;
    this.users.items.forEach($item => {
      if ($item.id === userId) {
        this.actionUser.email = $item.email;
        username = $item.first_name + ' ' + $item.last_name;
      }
    });
    const email = this.actionUser.email;
    this.user.resendActivationEmail(email).toPromise().then(
        (r: any) => {
          this.toasts.success('Mail sent successfully');
        },
        (error: Response) => {
          this.toasts.info('Already authorized');
          console.log(error);
        });
  }
}

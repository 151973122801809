import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { Observable } from "rxjs/Observable";

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { RestService } from "../../../rest/shared/rest.service";
import {PaginatedTriallines, Trialline} from "./trialline.model";

@Injectable()
export class TriallinesResourceService
{
  constructor(public http: RestService) {}

  paginate(page: number = 1): Observable<PaginatedTriallines>
  {
    return this.http.get('/admin/triallines?page=' + page).map(
      (response: Response) => {
        let body = response.json().body;

        return body;
      }
    );
  }

  get(id, withParam: string = null): Observable<Trialline>
  {
    return this.http.get('/admin/triallines/' + id + (withParam ? '?with=' + withParam : '')).map(
      (response: Response) => {
        console.log(response.json());
        let trialline = response.json().body.trialline;

        return trialline;
      }
    );
  }

  update(id, data): Observable<Trialline>
  {
    return this.http.post('/admin/triallines/' + id, data).map(
      (response: Response) => {
        let trialline = response.json().body.trialline;

        return trialline;
      }
    )
  };

  create(data): Observable<Trialline>
  {
    return this.http.post('/admin/triallines', data).map(
      (response: Response) => {
        let trialline = response.json().body.trialline;

        return trialline;
      }
    )
  };
}

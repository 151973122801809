import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
// import {useAnimation} from "@angular/core/src/animation/dsl";
import {ToastrService} from "ngx-toastr";
import {Trialline} from "../shared/trialline.model";
import {TriallinesResourceService} from "../shared/triallines.resource-service";

declare var _: any;

@Component({
  selector: 'app-main-triallines-details',
  templateUrl: './details.component.html'
})

export class TriallineDeatilsComponent implements OnInit
{
  trialline: Trialline;

  errors: any = {};

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private _route: ActivatedRoute,
    protected triallinesResource: TriallinesResourceService,
    protected toasts: ToastrService
  ) {
  }

  ngOnInit()
  {
    this.route.data.subscribe((data: {trialline: Trialline}) => {

      this.trialline = data.trialline;
    });
  }

  updateTrialline(form: NgForm) {

    this.errors = {};
    let data = form.value;

    this.triallinesResource.update(this.trialline.id, data).toPromise()
      .then((trialline: Trialline) => {

        _.extend(this.trialline, trialline);
        this.toasts.success('Successfully updated')
      }).catch(error => {

      if (error.status == 422) {
        let errorData: any = error.json();
        this.errors = errorData.data;
      } else {

        console.log(error.json());
      }
    })
  }
}

import { NgModule } from '@angular/core';
import { HttpModule } from "@angular/http";
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import {EmptyComponent} from "./empty/empty.component";
import {FullComponent} from "./full/full.component";
import {LeftSidebarComponent} from "./full/left-sidebar/left-sidebar.component";
import {NavbarComponent} from "./full/navbar/navbar.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpModule,
    RouterModule,
  ],
  declarations: [
    EmptyComponent,
    FullComponent,
    LeftSidebarComponent,
    NavbarComponent
  ],
  providers: [
  ]
})
export class LayoutsModule {}

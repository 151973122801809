import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {TriallinesResourceService} from "./triallines.resource-service";
import {Trialline} from "./trialline.model";

@Injectable()
export class TriallineResolver implements Resolve<Trialline>
{
  constructor(protected triallinesResource: TriallinesResourceService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Trialline>
  {
    return this.triallinesResource.get(route.params['id'])
      .toPromise().then((trialline: Trialline) => {

        return <any>trialline;
      })
      .catch((error: any) => {});
  }
}

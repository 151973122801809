import { Component } from '@angular/core';

@Component({
  selector: 'error-page',
  templateUrl: '../pages/error-page.html'
})

export class ErrorPageComponent {

  constructor() {
  }

}

import {AfterViewChecked, Component, OnDestroy} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-layout-empty',
  templateUrl: './empty.component.html',
})
export class EmptyComponent {

  routeFragmentSubscription: any;

  constructor(private route: ActivatedRoute) { }
}

import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { Observable } from "rxjs/Observable";

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { RestService } from "../../../rest/shared/rest.service";
import {PaginatedSubscriptionDiscounts, SubscriptionDiscounts} from "./subscription-discounts.model"; 
import { error } from "protractor";


@Injectable()
export class SubscriptionDiscountsResourceService
{
  constructor(public http: RestService) {}

  paginate(page: number = 1): Observable<PaginatedSubscriptionDiscounts>
  {
    return this.http.get('/admin/subscription/discounts?page=' + page).map(
      (response: Response) => {
        let body = response.json().body;

        return body;
      }
    );
  }

  get(): Observable<SubscriptionDiscounts>
  {
    return this.http.get('/admin/subscription/discount').map(
      (response: Response) => {
        return response.json();
      }
    );
  }
  
  create(data): Observable<SubscriptionDiscounts>
  {
    return this.http.post('/admin/subscription/discount/create', data).map(
      (response: Response, err) => {
        return response.json().body.coupon;
      }
    )
  };

  
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {PaginatedTriallines} from "./shared/trialline.model";
import {TriallinesResourceService} from "./shared/triallines.resource-service";

declare var moment: any;

@Component({
  selector: 'app-main-triallines',
  templateUrl: './triallines.component.html'
})

export class TriallinesComponent implements OnInit
{
  triallines: PaginatedTriallines;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected triallinesResource: TriallinesResourceService
  ) {
  }

  ngOnInit()
  {
    this.route.data.subscribe((data: {triallines: PaginatedTriallines}) => {

      this.triallines = data.triallines;
    });
  }

  getDate(date) {

    return moment(date);
  }

  paginate(page: number)
  {
    this.triallinesResource.paginate(page).toPromise().then(
      (data: PaginatedTriallines)  =>  {

        let navigationExtras: NavigationExtras = {
          queryParams: { page: page }
        };

        this.router.navigate(['/triallines'], navigationExtras);
        this.triallines = data;
      }
    )
  }
}

import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { Observable } from "rxjs/Observable";

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { RestService } from "../../../rest/shared/rest.service";
import {PaginatedCoupons, Coupon} from "./coupon.model";

@Injectable()
export class CouponResourceService
{
  constructor(public http: RestService) {}

  paginate(page: number = 1): Observable<PaginatedCoupons>
  {
    return this.http.get('/admin/coupons?page=' + page).map(
      (response: Response) => {
        let body = response.json().body;
        return body;
      }
    );
  }

  get(): Observable<Coupon>
  {
    return this.http.get('/admin/coupons').map(
      (response: Response) => {
        return response.json().body;
      }
    );
  }

  allList(): Observable<Coupon>
  {
    return this.http.get('/admin/coupons/alllist').map(
      (response: Response) => {
        return response.json().body;
      }
    );
  }

  byID(): Observable<Coupon>
  {
    return this.http.get('/admin/coupons/by/id').map(
      (response: Response) => {
        return response.json().body;
      }
    );
  }
 

  create(data): Observable<Coupon>
  {
    return this.http.post('/admin/coupons/create', data).map(
      (response: Response) => {
        return response.json().body.coupon;
      }
    )
  };

  
}

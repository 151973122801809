import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {TriallinesResourceService} from "./triallines.resource-service";
import {PaginatedTriallines} from "./trialline.model";

@Injectable()
export class TriallinesResolver implements Resolve<any>
{
  constructor(protected triallinesResourve : TriallinesResourceService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<PaginatedTriallines>
  {
    return this.triallinesResourve.paginate(route.queryParams['page'])
      .toPromise().then((triallines: PaginatedTriallines) => {

        return <any>triallines;
      })
      .catch((error: any) => {});
  }
}

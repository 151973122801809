import {Component} from '@angular/core';
import {AuthResourceService} from "../shared/auth-resoruce.service";
import {Router} from "@angular/router";
import {AuthService} from "../shared/auth.service";
import {User} from "../shared/user.model";
import 'rxjs/add/operator/toPromise';

@Component({
  selector: 'app-auth-login',
  templateUrl: './login.component.html'
})

export class LoginComponent {

  errors: any = {};
  processing: boolean = false;

  constructor(
    protected authResourceService: AuthResourceService,
    protected router: Router,
    protected authService: AuthService
  ) {

  }

  login(form: any) {
    // console.log('form', form);
    this.processing = true;

    this.authResourceService.login(form.value).toPromise().then(
      (response: User) => this.handleLogin(response),
      (error: Response) => this.handleError(error)
    );
  }

  //TODO Need refactor!
  redirect() {
    let userType = -1;
    let page = '/users';

    try {
      userType = this.authService.userModel.user_type;
    } catch (e) {
      setTimeout(() => {
        this.redirect();
      }, 500);
    }

    if (userType === 0) {
      page = '/dashboard';
    }
    this.router.navigateByUrl(page);
  }

  handleLogin(response: any) {
    this.processing = false;

    this.authService.store(response.token);

    this.authService.emit();

    this.redirect();

    // if(this.authService.userModel == undefined){
    //   this.authService.loadUser();
    // }

    // let page = '/users';
    // if (this.authService.userModel.user_type === 0) {
    //   page = '/dashboard';
    // }
    // this.router.navigateByUrl(page);
  }

  handleError(error: Response) {
    this.processing = false;

    if (error.status == 422) {
      let errorData: any = error.json();
      this.errors = errorData.data;
    }
  }

}


import {Directive, ElementRef, HostListener, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[sort]'
})
export class SortDirective implements OnChanges {

  @Input() public sort: any;
  @Input() public col: string;
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges)
  {
    console.log('here');
    if (this.col == this.sort.sort_col) {

      let i = this.renderer.createElement('i');
      this.renderer.addClass(i, 'sli-arrow-' + (this.sort.sort_dir == 'asc' ? 'down' : 'up'));
      this.renderer.appendChild(this.elRef.nativeElement, i);
    }
  }
}
